import React from "react";

// 路由视图组件
import Home from "../containers/index/Home";
import Errands from "../containers/index/errands";
import Customized from "../containers/index/Customized";
import CaseList from "../containers/index/case_list";
import Information from "../containers/index/Information";
import About from "../containers/index/About/About";
import InformationDetail from "../containers/index/information_detail";
import TakeOutFood from "../containers/index/take_out_food";
import DispatchSystem from "../containers/index/Dispatch_system";
import DownloadCenter from "../containers/index/DownloadCenter";
import Service from "../containers/index/Service";
import MultiPlatform from "../containers/index/MultiPlatform";
import CampusPlatform from "../containers/index/Campus_platform";
import Self from '../containers/index/self';




// 首页--板块2的图片
import homePlate2Icon1 from "../asset/home_phone1.png";
import homePlate2Icon2 from "../asset/home_phone2.png";
import homePlate2Icon3 from "../asset/home_phone3.png";
import homePlate2Icon4 from "../asset/home_phone4.png";

// router
export const routerArr = [
  {
    path: "/",
    render: props => <Home {...props} />
  },
  {
    path: "errands",
    render: props => <Errands {...props} />
  },
  {
    path: "take_out_food",
    render: props => <TakeOutFood {...props} />
  },
  {
    path: "customized",
    render: props => <Customized {...props} />
  },
  {
    path: "case_list",
    render: props => <CaseList {...props} />
  },
  {
    path: "information",
    render: props => <Information {...props} />
  },
  {
    path: "about",
    render: props => <About {...props}/>
  },
  {
    path: "information_detail",
    render: props => <InformationDetail {...props} />
  },
  {
    path: "dispatch_system",
    render: props => <DispatchSystem {...props} />
  },
  {
    path: "download_center",
    render: props => <DownloadCenter {...props} />
  },
  {
    path: "multi_platform",
    render: props => <MultiPlatform {...props} />
  },
  {
    path: "multi_platform",
    render: props => <MultiPlatform {...props} />
  },
  {
    path: "campus_platform",
    render: props => <CampusPlatform {...props} />
  }, 
  {
    path: "self",
    render: props => <Self {...props} />
  }
];

// 导航
export const navBarArr = [
  {
    title: "首页",
    dec: "HOME",
    path: "/"
  },
  {
    title: "产品中心",
    dec: "PRODUCT ",
    list: [{ path: "/errands", title: "趣来达 - 跑腿配送系统",icon:'icon_nav_run' }, { path: "/take_out_food", title: "趣来达 - 同城外卖系统",icon:'icon_nav_life' }],
    list2:[
      {
        type: 'product',
        icon: 'icon-head-product',
        title: '来云台',
        list: [
          {
            title: 'AI云配服务生态平台',
            url: 'https://laiyuntai.com/',
            tipType: "hot",
            type: 'link',
            id: '044',
            path: "/"
          },
        ]
      },
      {
        type:'product',
        icon:'icon-head-product',
        title:'产品',
        list:[
          {
            title: '自配送系统',
            path: '/self',
            tipType:"hot",
            id:'013'
          },
          {
            title: "跑腿配送系统",
            path: "/errands",
            id:'010'
          },
          {
            title: "同城配送调度系统",
            path: "/dispatch_system",
            id:'011'
          },
          {
            title: "外卖平台系统",
            path: "/take_out_food",
            tipType:"hot",
            id:'012'
          }
        ]
      },
      {
        type:'terrace',
        icon:'icon-head-terrace',
        title:'平台',
        list:[
          {
            title: "校园外卖跑腿平台",
            path: "/campus_platform",
            tipType:"hot",
            id:'020'
          },
          {
            title: "同城生活O2O系统",
            path: "/system_oto",
            id:'021'
          },
          {
            title: "多平台运营系统",
            path: "/multi_platform",
            tipType:"new",
            id:'022'
          }
        ]
      },
      {
        type:'serve',
        icon:'icon-head-serve',
        title:'服务',
        list:[
          {
            title: "SaaS租用",
            path: "/service?id=1",
            tipType:"hot",
            id:'030'
          },
          {
            title: "OEM品牌定制",
            path: "/service?id=3",
            tipType:"hot",
            id:'031'
          },
          {
            title: "特殊功能定制",
            path: "/service?id=4",
            id:'032'
          },
          {
            title: "独立部署",
            path: "/service?id=1",
            tipType:"new",
            id:'033'
          }
        ]
      },
      {
        type:'download',
        icon:'icon-head-serve',
        title:'下载',
        list:[
          {
            title: "用户端体验",
            path: "/download_center?id=1",
            tipType:"test",
            id:'040'
          },
          {
            title: "商家APP",
            path: "/download_center?id=2",
            id:'041'
          },
          {
            title: "商户后台",
            path: "/download_center?id=3",
            id:'042'
          },
          {
            title: "骑手APP",
            path: "/download_center?id=4",
            id:'043'
          }
        ]
      }
    ]
  },
  {
    title: "软件定制",
    dec: "CUSTOMIZE",
    path: "/customized"
  },
  {
    title: "客户案例",
    dec: "CASES",
    path: "/case_list"
  },
  {
    title: "行业资讯",
    dec: "NEWS",
    path: "/information"
  },
  {
    title: "关于我们",
    dec: "ABOUT",
    path: "/about"
  }
];

// 首页
export const homeArr = [
  {
    title: "便捷入口用户自定义下单",
    img: homePlate2Icon1,
    list: [
      { title: "小程序快速入口，用完即走", icon: "ic_search" },
      { title: "精准地图式定位，天气温度显示", icon: "ic_loction" },
      { title: "显示附近骑手数量，预计接单时间", icon: "ic_time" },
      { title: "优先附近商家，按条件筛选", icon: "ic_store" }
    ],
    index: 0,
    path: "/errands#distribution"
  },
  {
    title: "丰富营销拉动用户裂变",
    img: homePlate2Icon2,
    list: [
      { title: "新客免单，店铺新客首单减免", icon: "ic_ free" },
      { title: "邀请有礼，邀请新用户或优惠券红包", icon: "ic_gift" },
      { title: "视频营销，直播带货思路加速同城营销", icon: "ic_ic_video" },
      { title: "用户分享，店铺收藏与转发分享", icon: "ic_share" }
    ],
    path: "/take_out_food#logical",
    index: 1
  },
  {
    title: "海量玩法增强平台用户黏性",
    img: homePlate2Icon3,
    list: [
      { title: "优惠券营销，薄利多销折扣吸引", icon: "ic_sale" },
      { title: "满减活动，下单金额满足条件可享优惠", icon: "ic_loction(1)" },
      { title: "余额充值，锁住用户长期消费", icon: "ic_Pay" },
      { title: "常用地址，快速下单方便复购", icon: "ic_address" }
    ],
    path: "/take_out_food#highlights",
    index: 1
  },
  {
    title: "优质系统提升用户平台体验",
    img: homePlate2Icon4,
    list: [
      { title: "便捷小程序，一次使用长期留存", icon: "ic_foever" },
      { title: "优质系统，提升用户使用好感", icon: "ic_system" }
    ],
    path: "/take_out_food#common",
    index: 1
  }
];

export const homeSystem = [
  {
    img: "img_life",
    img2: "img_life2",
    icon: "ic_life",
    title: "趣来达-同城生活系统",
    btn: "查看产品详情 More+",
    content:
      "打造本地万能生活服务平台，搭建集线上点餐、接单、打印、调度、配送功能于一体的同城生活服务平台，成熟的O2O系统，轻松接轨“互联网+”",
    path: "/errands"
  },
  {
    img: "img_delivery",
    img2: "img_delivery2",
    icon: "ic_delivery",
    title: "趣来达-跑腿配送系统",
    btn: "查看产品详情 More+",
    content:
      "打通同城跑腿配送全流程，支持帮我买、帮我送、代排队、帮我办等跑腿服务，全套解决方案包括跑腿系统用户端、商家端、配送端以及强大的后台调度管理系统。",
    path: "/take_out_food"
  }
];

export const homeBlateThree = [
  {
    list: [
      {
        icon: "ic_services1",
        title: "万能跑腿",
        title2: "Universal running errands"
      },
      {
        icon: "ic_services2",
        title: "快递送取",
        title2: "Express take to send"
      },
      {
        icon: "ic_services3",
        title: "同城代办",
        title2: "City do STH for sb"
      }
    ]
  },
  {
    list: [
      {
        icon: "ic_services4",
        title: "餐饮外卖",
        title2: "Catering takeout"
      },
      {
        icon: "ic_services5",
        title: "生鲜配送",
        title2: "Fresh distribution"
      },
      {
        icon: "ic_services6",
        title: "商超联盟",
        title2: "Businness union"
      }
    ]
  },
  {
    list: [
      {
        icon: "ic_services7",
        title: "线上购物",
        title2: "Online shopping"
      },
      {
        icon: "ic_services8",
        title: "上门服务",
        title2: "Visiting service"
      },
      {
        icon: "ic_services9",
        title: "附近零售",
        title2: "Near the retail"
      }
    ]
  }
];

export const phoneMask = [
  {
    title: "营销功能提升用户量",
    dec: "跑腿配送系统内设置优惠券等营销功能，刺激用户裂变营销",
    img: "phone1"
  },
  {
    title: "配送员实名认证",
    dec: "跑腿配送平台内所有配送员必须实名认证，官方审核上岗",
    img: "phone2"
  },
  {
    title: "群抢单、指派单",
    dec: "配送系统内多种派单、发单的模式并行，确保订单及时响应进行",
    img: "phone3"
  },
  {
    title: "奖惩并行，规范平台",
    dec: "对配送员实行奖惩制度，规范平台运行，确保无安全、财产纠纷",
    img: "phone4"
  },
  {
    title: "订单进程实时推送",
    dec: "一系列跑腿配送进程，均在系统内实时推送给用户、商家、配送员",
    img: "phone5"
  },
  {
    title: "订单行程实时监控",
    dec: "配送员接单情况、所在区域等信息均详细显示，方便客户查看进度",
    img: "phone6"
  },
  {
    title: "后台跑腿调度系统",
    dec: "跑腿智能调度系统智能处理订单派发情况，确保跑腿平台良好运转",
    img: "phone7"
  },
  {
    title: "所有订单管理追溯",
    dec: "跑腿配送系统内所有订单可长期追溯，数据长期留存",
    img: "phone8"
  }
];

// 产品介绍
export const deliveryArr = [
  {
    imgUrl: "icon1.png",
    describe: "美食生鲜百货，想购就购足不出户 购遍全城 。一键下单，快速送达",
    text: "代买"
  },
  {
    imgUrl: "icon2.png",
    describe: "没时间，不想出门，趣来达一下在线下单，快速上门取件",
    text: "代送"
  },
  {
    imgUrl: "icon3.png",
    describe: "取文件、钥匙、发票或其他物品，无须亲力亲为，随时随地享受服务。",
    text: "代取"
  },
  {
    imgUrl: "icon4.png",
    describe: "排队、抢票、等号.......琐事太多没时间处理？一键下单，代办全搞定",
    text: "代办"
  }
];

export const iconArrOne = [
  {
    title: "地图指派",
    icon: "icon3"
  },
  {
    title: "区域配送",
    icon: "icon4"
  },
  {
    title: "智能群抢单",
    icon: "icon5"
  },
  {
    title: "送单计价",
    icon: "icon6"
  },
  {
    title: "配送时长",
    icon: "icon7"
  },
  {
    title: "位置监控",
    icon: "icon8"
  }
];

export const iconArrTwo = [
  {
    title: "配送员权限",
    icon: "icon12"
  },
  {
    title: "发单计价",
    icon: "icon9"
  },
  {
    title: "订单统计",
    icon: "icon11"
  },
  {
    title: "智能调度",
    icon: "icon10"
  },
  {
    title: "系统通知",
    icon: "icon1"
  }
];

// 软件定制
export const customizedBlateFour = [
  {
    list: [
      {
        title: "校园跑腿配送",
        icon: "blate1"
      },
      {
        title: "跑腿公司",
        icon: "blate2"
      },
      {
        title: "同城区域配送",
        icon: "blate3"
      }
    ]
  },
  {
    list: [
      {
        title: "快递公司",
        icon: "blate4"
      },
      {
        title: "同城生活服务平台",
        icon: "blate5"
      },
      {
        title: "本地电商平台",
        icon: "blate6"
      }
    ]
  }
];

export const customizedBlateThree = [
  {
    title: "专业性",
    dec:
      "壹立科技专注于互联网信息技术及软件系统技术服务，旨在为各大行业领域及商家提供优质技术产品服务，保障解决各商家技术难题，核心应用于商家营销推广、吸粉引流的运营场景里，让平台、让商家拥有获取更多流量的技术基奠能力",
    icon: "icon7"
  },
  {
    title: "灵活性",
    dec:
      "壹立科技始终提供高效率、稳定化、个性化的技术服务，为客户提供全新的和创新性的途径来吸引消费者，同时提高在基础技术服务和定制服务的灵活性",
    icon: "icon8"
  },
  {
    title: "定制化",
    dec: "建立在壹立科技成熟的软件技术开发流程之上，并通过不断的迭代更新之后，定制出客户真正需要、真正喜欢的个性化产品",
    icon: "icon9"
  }
];

// 产品介绍 ---- 趣来达优购
export const shoppingPlate2 = [
  {
    icon: "plate2_icon1",
    dec: "PC管理端+微信公众号+小程序，资源数据互通，操作灵活，轻量级应用"
  },
  {
    icon: "plate2_icon2",
    dec: "多元互动营销功能赋能商城：拼团、砍价、秒杀、积分、会员卡等丰富营销策略提升用户黏性"
  },
  {
    icon: "plate2_icon3",
    dec: "提供系统源码，商家拥有源码，不仅掌握了商城的运营权，更能完全操控后期开发"
  },
  {
    icon: "plate2_icon4",
    dec: "支持定制开发，独立部署商户专属品牌的电商系统，全面满足商户品牌塑造"
  },
  {
    icon: "plate2_icon5",
    dec: "快速搭建平台，趣来达优购是一套成熟的电商系统，授权即可商业运营，省去开发费用"
  },
  {
    icon: "plate2_icon6",
    dec: "低成本打造线上商城，让中小型企业快速从移动互联网时代迅速崛起"
  }
];

export const plate4Icon = {
  list1: [
    {
      icon: "plate4_icon1",
      title: "日化用品"
    },
    {
      icon: "plate4_icon2",
      title: "鞋服门店"
    },
    {
      icon: "plate4_icon3",
      title: "餐饮行业"
    },
    {
      icon: "plate4_icon4",
      title: "生鲜水果"
    }
  ],
  list2: [
    {
      icon: "plate4_icon5",
      title: "母婴用品"
    },
    {
      icon: "plate4_icon6",
      title: "教育培训"
    },
    {
      icon: "plate4_icon7",
      title: "医美机构"
    },
    {
      icon: "plate4_icon8",
      title: "了解更多"
    }
  ]
};
